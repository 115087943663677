import React, { useState } from "react"
import classNames from "classnames"
import useSound from "use-sound";
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

import MobileWindow from "./MobileWindow"

import * as styles from "./utils/mobilePages.module.scss"
import { handleSubmitEmail } from "../services/handleSubmitContact";
import FormErrorMessage from "../FormErrorMessage";
import openSound from "../../../static/sounds/hover.mp3"

const ContactPage = () => {
    const [isSuccess, setIsSuccess] = useState(false)
    const [hasError, setHasError] = useState(false)
    
    const [playOpenSound] = useSound(openSound, {volume: 0.5})

    const SuccessMessage = () => {
        playOpenSound()
        return (
            <div className="notification is-success is-light is-size-6 mt-4">
                <FontAwesomeIcon icon={faHeart} className="mr-3"/>
                Your message has been sent!
            </div>
        )
    }

    return (
        <MobileWindow title="contact">
            <div className="p-6">
                <span className={classNames(styles["about__contentHeader"])}>
                    WORK EMAIL{' '}
                </span>
                <span>
                    <a
                        className="has-text-primary"
                        href="mailto:hi@sharyap.com"
                        rel="noopener noreferrer">
                        hi@sharyap.com</a>
                </span>
                <div className="mt-5">
                    <span className={classNames(styles["about__contentHeader"])}>
                        QUICK CONTACT
                    </span>
                    
                    <Formik
                        initialValues={{
                            name: "",
                            email: "",
                            subject: "",
                            message: "",
                        }}
                        validationSchema={
                            Yup.object({
                                name: Yup.string().required("This field is required"),
                                email: Yup.string().email("Please input a valid email address").required("This field is required"),
                                subject: Yup.string().required("This field is required"),
                                message: Yup.string().required("This field is required"),
                            })}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setIsSuccess(false);
                            setHasError(false);
                            setTimeout(() => {
                                handleSubmitEmail(values, setIsSuccess);
                                setSubmitting(false);
                                resetForm();
                            }, 400);
                        }}
                    >
                        {({ isSubmitting }) => (
                        <Form>
                            <Field
                                type="text"
                                name="name"
                                className="input mt-2"
                                placeholder="Your name"
                                validate={setHasError(true)}
                                style={{borderRadius: "15px"}}
                            />
                            <ErrorMessage name="name" component={FormErrorMessage} />

                            <Field
                                type="email"
                                name="email"
                                className="input mt-2"
                                placeholder="Your email"
                                validate={setHasError(true)}
                                style={{borderRadius: "15px"}}
                            />
                            <ErrorMessage name="email" component={FormErrorMessage} />

                            <Field
                                type="text"
                                name="subject"
                                className="input mt-2"
                                placeholder="Subject"
                                validate={setHasError(true)}
                                style={{borderRadius: "15px"}}
                            />
                            <ErrorMessage name="subject" component={FormErrorMessage} />
                            <Field
                                as="textarea"
                                name="message"
                                className="textarea has-fixed-size mt-2"
                                placeholder="What's up?"
                                validate={setHasError(true)}
                                style={{borderRadius: "15px"}}
                            />
                            <ErrorMessage name="subject" component={FormErrorMessage} />

                            <button
                                className={classNames("button is-fullwidth is-rounded is-primary mt-3",
                                    { "is-loading": isSubmitting }
                                )}
                                type="submit"
                                disabled={isSubmitting}
                                style={{borderRadius: "15px"}}
                                >
                                Submit
                            </button>

                            {isSuccess ? <SuccessMessage /> : null}
                        </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </MobileWindow>
    )
}
export default ContactPage